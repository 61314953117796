export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'tratave',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.345.57',
	MODULES: [
		'atex-inspections',
		'digital-twin',
		'asset-planning',
		'gap-analysis',
		'dl-50',
		'inspections',
		'observations',
		'pipeline-integrity',
		'epi',
		'repairs'
	],
	TIMESTAMP: '2024-10-30T19:32:58.881Z',
	COMMIT: '1cfdf5870f1044ccd70a486ba3cdd4ad53399574',
	BRANCH: 'master',
	API_SERVER: 'https://tratave-inspections-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://tratave-inspections-resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
